<template>
  <div v-if="activity">
    <validation-observer ref="form" v-slot="{ handleSubmit }">
      <v-form @submit.stop.prevent="handleSubmit(onSubmit)">
        <v-card outlined>
          <v-card-title v-if="activity.id">Editing {{ translate(activity.name) }}</v-card-title>
          <v-card-title v-else>Creating new activity</v-card-title>
          <v-card-text>
            <v-row>
              <v-col md="6">
                <m-field v-model="activity.code" m="activity" a="code" />
              </v-col>
              <v-col md="6">
                <m-field v-model="activity.sector_id" m="activity" a="sector_id"
                        component="v-select" :items="sectors" item-value="id" :item-text="`name.${$i18n.locale}`" />
              </v-col>
            </v-row>
            <m-field v-model="activity.name" m="activity" a="name" i18n />
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn type="submit" text color="primary">{{$t('actions.save')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </validation-observer>
  </div>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";
import ImageUploader from 'vue-image-upload-resize';
import formatter from "@/mixins/formatter";

@Component({components: {ImageUploader}, mixins: [formatter]})
export default class ActivityForm extends Vue {

  @Prop() activityId;

  activity = null;
  sectors = [];

  mounted() {
    this.$api.get(`sector`).then(j => {
      this.sectors = j.data;
    });
    // get or create activity object
    if (this.activityId) {
      this.$api.get(`activity/${this.activityId}`).then(j => {
        this.activity = j.data;
      });
    } else {
      this.activity = {
        name: {},
      }
    }
  }

  onSubmit() {
    const call = this.activityId
      ? this.$api.patch(`activity/${this.activityId}?expand=sector`, this.activity)
      : this.$api.post(`activity?expand=sector`, this.activity)

    call.then(j => {
      this.$emit('save', j.data);
    }).catch(err => {
      if (err.response) this.$refs.form.setErrors(err.response.data);
      this.$snack.validationError(err.response.data)
    });
  }

  get rules() {
    return this.$store.state.rules;
  }

}
</script>

<style>
#fileInput {
  display: none;
}
h2 {
  margin: 1em 0;
}
</style>
